.hs-form-field {
  margin-bottom: 1.4rem;
}

.hs-form-required {
  color: #EF6B51;
}

.hs-form-field label {
  margin: 0 1px 3px!important;
}

.hs-input {
  background: #ffffff;
  border: 0;
  margin-top: 3px;
  color: #000;
  outline: 0;
  transition: .2s linear all;
  border-radius: 4px 4px 0 0;
  max-width: 100%;
  box-shadow: 2px 2px 2px #00000008;
}

.hs-input[type="text"], .hs-input[type="email"], .hs-input[type="tel"], .input select {
  font-size: 0.875rem;
  padding: 0.7rem;
  width: 100%;
  box-shadow: 2px 2px 2px #00000008;
}

label.hs-error-msg {
  background: #fff;
  color: #c92d1f;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  width: 250px;
  margin: 0;
}

.hs-input[type=checkbox], .hs-input[type=radio] {
  cursor: pointer;
  margin-right: 0.35rem;
}

.hs_aceito_que_meus_dados_de_contato_sejam_salvos_.hs-aceito_que_meus_dados_de_contato_sejam_salvos_.hs-fieldtype-checkbox.field.hs-form-field {
  border: 1px solid #9e9e9e;
  padding: 12px 12px 2px 12px;
  border-radius: 4px;
  width: 98%;
  background: transparent;
  box-shadow: 0 0 10px #00000047;

}

.hs-fieldtype-checkbox {
  padding: 10px;
  box-shadow: 0 0 10px #00000047;
}

.hs-button.primary {
  background: linear-gradient(27deg, #ff0040 0%, #ff0040 49%, #ff0040 100%);
  background-size: 200%;
  background-position: 50% center;
  border: 0;
  cursor: pointer;
  color: #fff;
  padding: 12px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  text-transform: uppercase;
  width: 100%;
  transition: .1s linear all;
}

.form-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-icon {
  animation: spin 0.8s linear infinite
}

@-ms-keyframes spin {
  from { -ms-transform: rotate(0deg); }
  to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
  from { -moz-transform: rotate(0deg); }
  to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  from { -webkit-transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
}

ul.no-list, ul.inputs-list {
  list-style-type: none !important;
}

form .inputs-list > li {
  margin: 0.7rem 0;
  list-style: none;
}

.lead-container form .input input[type="text"], .lead-container form .input input[type="email"], .lead-container form .input input[type="tel"] {
  background-color: transparent !important;
  border: 1px solid #758690 !important;
}

.download-guia {
  align-items: center;
  background-color: #ff0040;
  border-radius: 30px;
  color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 26px 10px 10px;
  padding: 10px 15px;
  text-decoration: none;
  transition: all .2s linear;
  width: 100%;
  font-weight: 600;
}
