* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Igual;
  src: url("../assets/fonts/Igual-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Igual;
  src: url("../assets/fonts/Igual-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Igual;
  src: url("../assets/fonts/Igual-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Igual;
  src: url("../assets/fonts/Igual-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: Igual;
  src: url("../assets/fonts/Igual-ExtraBold.ttf");
  font-weight: 800;
}

html {
  scroll-behavior: smooth;
}

body,
input,
button {
  font-family: "Igual";
}

body {
  min-height: 100%;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style-type: disc;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 93.75%;
  }
}

.overflow-hidden {
  width: 100%; 
  height: 100vh;
  overflow: hidden;
  touch-action: none;
}
